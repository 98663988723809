
  import { defineComponent, ref, watch } from "vue";
  import ApiService from "@/core/services/ApiService";
  
  export default defineComponent({
    name: "rejection-filters",
    props: {
      applied_filters: Object,
      allowed_filters: {
        type: Array,
        required: false,
        default: () => {
          return [
            "type",
            "created_at",
            "rejection_reason",
          ];
        },
      },
    },
    data() {
      return {
        rejectionOptions: [{}] ,
        filters: {
          type: "",
          created_at: [],
          rejectionReason: "",
        },
      };
    },
    emits: ["changed"],
    methods: {
      resetFilters() {
        this.filters = {
          type: "",
          created_at: [],
          rejectionReason: "",
        };
        this.dataChanged();
      },
      dataChanged() {
        this.$emit("changed", this.filters);
      },
    },
    mounted() {
      watch(this.filters, () => {
        this.dataChanged();
      });
  
      (this.filters as any) = this.applied_filters;
  
      ApiService.get("settings/rejection_reasons").then((response) => {
        this.rejectionOptions = response.data.data;

      });
    },
  });
  