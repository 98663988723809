
  import { defineComponent, ref } from "vue";
  import ApiService from "@/core/services/ApiService";
  import RejectionFilters from "./pending-requests/RejectionFilters.vue";
  
  export default defineComponent({
    name: "nonConformance-table",
    components: {
      RejectionFilters,
    },
    props: {
      widgetClasses: String,
      filterData: {
        type: Object,
        required: false,
        default: () => {
          return {};
        },
      },
    },
    data() {
      return {
        rejectionReason: "",
        columns: [],
        entities: [],
        filters: {},
        count: 0,
        pagination: {
          page: 1,
          perPage: 10,
          total: 0,
        },
        loading: false,
        initialized: false,
        selectedAll: false,
      };
    },
    methods: {
      reloadData() {
        this.retrieveData();
      },
      retrieveData() {
        this.initialized = true;
        this.loading = true;
        ApiService.query("rejection_reasons", {
          params: {
            filters: this.filters,
            perPage: this.pagination.perPage,
            page: this.pagination.page,
          },
        }).then((response) => {
          this.$emit("onRetrieve", response.data);
          this.loading = false;
          this.entities = response.data.data;
          console.log(response.data.ta)
          this.columns = response.data.columns; 
          this.pagination.total = response.data.meta.total;
          this.count = response.data.meta.total;
        }).catch((error) => {
          console.error("Error fetching rejection reasons data:", error);
          this.loading = false;
        });
      },
      getEntityValue(entity, columnName) {
        return entity[columnName] ?? "N/A";
      },
      getColumnClass(column) {
        return column.sortable ? "sortable-column" : "";
      },
      getCustomColumnClass(columnName) {
        switch (columnName) {
        case "type":
          return "column-type";
        case "status":
          return "column-status";
        default:
          return "";
        }
      },
      updateFilters(newFilters) {
        this.filters = newFilters;
        this.retrieveData();
      },
      formatDate(date) {
        return new Date(date).toLocaleDateString();
      },
    },
    mounted() {
      this.retrieveData();
    },
    setup() {}
  });
  